import { render, staticRenderFns } from "./livestockDetails.vue?vue&type=template&id=861402d4&scoped=true&"
import script from "./livestockDetails.vue?vue&type=script&lang=js&"
export * from "./livestockDetails.vue?vue&type=script&lang=js&"
import style0 from "./livestockDetails.vue?vue&type=style&index=0&id=861402d4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "861402d4",
  null
  
)

export default component.exports