<template>
  <div class="box">
    <van-nav-bar
      :title="position"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- 天气实况 -->
    <my-live-weather :lngLat="lngLat"></my-live-weather>
    <div>
      <div class="box-title">{{ this.names }}</div>
      <!-- 信息div、 -->
      <div class="box-info">
        <!-- 图片 -->
        <div class="box-info-image">
          <div class="box-info-image-img"><img :src="animalfrom.imageUrl" alt=""></div>
          <div class="box-info-image-varieties">{{animalfrom.animal_variety_name}}</div>
        </div>
        <div class="box-info-content">
          <div class="info-top">
            <div>{{animalfrom.breed_condition_name}}</div>
            <!-- 碳 -->
            <!-- <div class="carbon"></div> -->
          </div>
          <div class="info-content">入栏日期:{{animalfrom.enter_date}}</div>
          <div class="info-dayage">畜禽日龄:{{animalfrom.dayCount}}天({{animalfrom.dayCount2}})</div>
          <div class="info-center">预计出栏:{{animalfrom.planOutDate}}(还有{{animalfrom.plant_out_days}}天)</div>
          <div class="info-bottom">
            <div class="count">存栏数量:{{animalfrom.exist_num}}</div>
            <div class="info-bottom-button"><button @click="outColumn">出栏</button></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 养殖操作 -->
    <div class="breed">
      <div><span>养殖操作:</span>{{animalfrom.animalOperate}}</div>
      <div><span>易发病害:</span>{{animalfrom.animalDisease}}</div>
      <div><span>易发气象灾害:</span>{{animalfrom.animalWeather}}</div>
    </div>
    <!-- tab栏 -->
    <div style="margin-top: 2vh">
      <van-tabs type="card" color="#1989fa" v-model="active">
        <van-tab title="养殖操作">
          <van-collapse accordion v-model="activeNames" v-for="item in operation" :key="item.id">
            <van-collapse-item :name="item.id">
              <template #title>
                <div>{{item.oper_name}}</div>
                <div v-for="item in item.animalOperateRecList" :key="item.id" style="display:flex;justify-content: space-around;font-weight:550">
                  {{item.create_time}}
                <div>{{item.content}}</div>
                </div>
              </template>
              <div style="display:flex;align-items:center">
                <input type="text" :placeholder="item.oper_name + '一次'" v-model="content" style="width:100px">
                <van-uploader v-model="fileList" />
                <button @click="record(item.id, item.oper_name)">记录</button>
              </div>
            </van-collapse-item>
          </van-collapse>
          <!-- 弹出框区域 -->
          <van-popup style="width:90vw" round v-model="show">
            <van-field v-model="count" label="存栏数量" />
            <van-field v-model="outCount" label="出栏数量" placeholder="请输入出栏数量" />
            <div style="padding:1vh 2vw;">
              <p style="font-weight: 550;">确认将本圈设为养殖状态设置为已出栏状态？</p>
              <p style="font-size:12px">待全部出栏后，本次养殖将不在本页面展示，相关信息可在我的》养殖管理》养殖历史中查看</p>
              <div style="display:flex;justify-content: space-between;">
                <van-button style="display:block;" type="info" size="small" @click="outColumnDetermine">确认</van-button>
                <van-button style="display:block;" type="info" size="small" @click="shows">取消</van-button>
              </div>
            </div>
          </van-popup>
        </van-tab>
        <van-tab title="饲养记录">
          <feeding-record :id="id"></feeding-record>
        </van-tab>
        <van-tab title="监测预报"></van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import feedingRecord from "./feedingRecord.vue"
import { NavBar, Tab, Tabs, Collapse, CollapseItem, Uploader, Dialog, Notify, Popup, Field, Button } from "vant";
import MyLiveWeather from "./liveWeather.vue";
import { getDetailsInit } from "../../service/Enclosure/Enclosure";
import { animalById, animalselectToday, animalSave, animalPen } from "../../service/livestock/livestock";
export default {
  props: ["id"],
  data() {
    return {
      animalfrom: {},
      lngLat: {},
      position: "",
      penId: "",
      names: "",
      active: -1,
      current: 1,
      size: 10,
      activeName: "1",
      animaperateList: "1",
      operation: [],
      activeNames: "1",
      fileList: [],
      animal_operate_name: "",
      content: "",
      show: false,
      count: "",
      outCount: ""
    };
  },
  components: {
    "van-nav-bar": NavBar,
    "my-live-weather": MyLiveWeather,
    "van-tab": Tab,
    "van-tabs": Tabs,
    "van-collapse": Collapse,
    "van-collapse-item": CollapseItem,
    "van-uploader": Uploader,
    [Notify.Component.name]: Notify.Component,
    "feeding-record": feedingRecord,
    "van-popup": Popup,
    "van-field": Field,
    "van-button": Button
  },
  computed: {},
  watch: {},
  mounted() {
    this.getById();
    this.getanimalselectToday()
  },
  methods: {
    // 返回上一级
    onClickLeft() {
      this.$router.go(-1);
    },
    // 获取圈舍信息
    async getList(id) {
      const res = await getDetailsInit(id);
      console.log(res);
      if (res.code === 200) {
        this.position = `${res.data.province_name}-${res.data.city_name}-${res.data.county_name}`;
        this.names = res.data.name;
        // const position = `${res.data.name}`;
        this.amapGeocoder(this.position).then((res) => {
          console.log(res);
          this.lngLat = {
            lat: res.lat,
            lng: res.lng
          };
        });
      }
    },
    // 获取圈舍id
    async getById() {
      const res = await animalById(this.id);
      if (res.code === 200) {
        this.animalfrom = res.data
        this.count = res.data.exist_num
        this.getList(res.data.pen_id);
      }
    },
    // 通过汉语地址 获取 经纬度
    amapGeocoder(position) {
      return new Promise((resolve, reject) => {
        AMap.plugin("AMap.Geocoder", function () {
          var geocoder = new AMap.Geocoder();
          geocoder.getLocation(position, function (status, result) {
            console.log(result);
            if (status === "complete" && result.geocodes.length) {
              var lnglat = result.geocodes[0].location;
              resolve(lnglat);
            } else {
              reject(new Error("根据地址查询位置失败"));
            }
          });
        });
      });
    },
    async getanimalselectToday() {
      const res = await animalselectToday(this.id)
      this.operation = res.data
      this.animal_operate_name = res.data.oper_name
    },
    record(animalOperateId, name) {
        Dialog.confirm({
          title: "提交记录信息",
          message: "您确认要提交嘛"
        }).then(() => {
          const param = new FormData();
          for (var i = 0; i < this.fileList.length; i++) {
            param.append("image", this.fileList[i].file)
          };
          param.append("animal_id", this.id);
          param.append("content", this.content);
          param.append("animal_operate_name", name)
          param.append("animal_operate_id", animalOperateId);
          animalSave(param).then((res) => {
            if (res.code === 200) {
              Notify({ type: "success", message: "添加成功" });
              this.getanimalselectToday()
              this.fileList = []
              this.content = ""
            } else {
              Notify({ type: "error", message: "添加失败" });
            }
          })
        });
    },
    outColumn() {
      this.show = true
    },
    async outColumnDetermine() {
      const param = {
        animal_id: this.id,
        num: this.outCount
      }
      const res = await animalPen(param)
      if (res.code === 200) {
        this.show = false
        Notify({ type: "success", message: "出栏成功" });
        this.$router.go(-1)
      }
    },
    shows() {
      this.show = false
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  .box-title {
    text-align: center;
    margin: 1.5vh 0 1.5vh 0;
    font-weight: 600;
  }
  .box-info {
    height: 20vh;
    display: flex;
    .box-info-image {
      width: 25vw;
      display: flex;
      flex-direction: column;
      .box-info-image-img {
        // height: 13vh;
        width: 100%;
        img{
          width: 100%;
        }
      }
      .box-info-image-varieties {
        text-align: center;
        margin-top: 2.5vh;
      }
    }
    .box-info-content {
      padding: 0.5vh 1vw;
      width: 75vw;
      // border: 1px solid red;
      .info-top {
        width: 60vw;
        margin-top: 1vh;
        display: flex;
        justify-content: space-between;
        .carbon {
          padding: 0 1vw;
          background: #d0e17d;
        }
      }
      .info-content {
        margin-top: 1vh;
        display: flex;
        justify-content: space-between;
      }
      .info-dayage {
        margin-top: 1vh;
      }
      .info-center {
        margin-top: 1vh;
      }
      .info-bottom {
        margin-top: 1vh;
        display: flex;
        justify-content: space-between;
        .info-bottom-button {
          color: #fff;
          button {
            margin-bottom: 1vh;
            background: #07c160;
            border: none;
            border-radius: 1.06667vw;
          }
        }
        .count {
          margin-top: 1vh;
        }
      }
    }
  }
  .breed {
    display: flex;
    flex-direction: column;
    height: 15vh;
    // border: 1px solid rebeccapurple;
    padding: 0.5vh 1vw;
    border-bottom: 1px solid #000;
    div {
      margin-top: 1.5vh;
      span {
        font-weight: 550;
      }
    }
  }
  .Breeding{
    margin-top: 1vh;
    .Breeding-title{
      display: flex;
    }
    ul{
      .li{
        margin-top: .5vh;
        display: flex;
        justify-content: space-around;
      }
    }
  }
}
</style>
